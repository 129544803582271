import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    if($('.m-nav').length) {
        var currentUrl = window.location.href;
        
        $('.m-nav-link').each(function(){
            var linkUrl = $(this).attr('href');

            if (currentUrl.indexOf(linkUrl) === 0) {
                $(this).addClass('-current');
            }
        });
    };

    if($('.m-nav-casestudies').length) {
        var currentUrl = window.location.href.replace(/\/$/, '');
        
        $('.m-nav-link').each(function(){
            var linkUrl = $(this).attr('href').replace(/\/$/, '');

            if (currentUrl.indexOf(linkUrl) !== -1) {
                $(this).addClass('-current');
            }
        });
    };

    if($('.m-nav-newsposts').length) {
        var currentUrl = window.location.href.replace(/\/$/, '');
        
        $('.m-nav-link').each(function(){
            var linkUrl = $(this).attr('href').replace(/\/$/, '');

            if (currentUrl.indexOf(linkUrl) !== -1) {
                $(this).addClass('-current');
            }
        });
    };

    $(".m-nav-mobile-select").on("click", function(){
        $(this).closest(".m-nav-mobile").find(".m-nav-dropdown").slideToggle();
        $(this).find(".fa-chevron-down").toggleClass("-upsideDown");
    });
});