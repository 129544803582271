import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-links-carousel.-isCarousel').each(function(i, obj) {
        var dots = $(this).attr('id');

        $(this).slick({
            dots: false,
            arrows: true,
            prevArrow: '<button type="button" class="slick-prev me-4"><i class="fa-solid fa-arrow-left-long" aria-hidden="true"></i><span class="fa-sr-only">Previous arrow</span></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa-solid fa-arrow-right-long" aria-hidden="true"></i><span class="fa-sr-only">Next arrow</span></button>',
            appendArrows: $('.m-links-arrows.'+dots),
            swipeToSlide: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            pauseOnHover: true,
            variableWidth: false,
            useAutoplayToggleButton: false,
            rows: 0,
            responsive: [
                {
                    breakpoint: 1800,
                    settings: {
                        slidesToShow: 3.4,
                    }
                },
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 3.2,
                    }
                },
                {
                    breakpoint: 1450,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 2.8,
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 2.4,
                    }
                },
                {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1.7,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1.5,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1.3,
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        });
    });
});
