import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $(".m-desc-card").hide();
    $(".m-desc-card:first").show();
    $(".m-desc-tab:first").addClass("-active");

    $(".m-desc-tab").on("click", function(){
        var tab = $(this).attr('id');

        console.log(tab);
        $(".m-desc-tab").removeClass("-active");
        $(this).addClass("-active");

        $('.m-desc-card').each(function(i, obj) {
            if($(this).hasClass(tab)) {
                $(this).show();
            } else {
                $(this).hide();
            };
        });
    });
     
    $(".m-desc-header").click(function(event) {
        $(this).closest(".m-desc-level").find(".m-desc-terms").slideToggle();
        $(this).closest(".m-desc-level").toggleClass("active");
        $(this).find(".m-desc-header-icon").toggleClass("not-active").toggleClass("active");
    });
    
    $(".m-desc-term").click(function(event) {
        $(this).find(".checkbox-element").toggleClass("active");

        if($(this).hasClass("active")) {
            $(this).removeClass("active");
            // var section = $(this).data("term");
            // $("." + section).remove();
        } else {
            $(this).addClass("active");
            // var section = $(this).data("term");
            // var section_title = $(this).data('taxonomy');
            // var section_content = $(this).find('.m-desc-term-content').html();
            // var title_div = $('<div class="m-desc-overview-name"></div>').html(section_title);
            // var content_div = $('<div class="m-desc-overview-content"></div>').append(title_div).append(section_content);
            // $('.m-desc-overview-inner').addClass("section").append(content_div);
        };
    });
});