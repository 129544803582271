import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {


});

function iframeLoaded() {
    var iFrameID = document.getElementById('scqf-register');
    if(iFrameID) {
        iFrameID.height = "";
        iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";
    }   
}