import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $("#searchButton").on("click", function(){
        $("#searchForm").toggleClass("-show");
    });

    $(document).on("click", function(e) {
        if($(this).is("#searchForm, #searchButton") === false) {
            $("#searchform").removeClass("-show");
        };
    });

    if ($(window).width() < 768) {
        $('#header').addClass('-dark');
    } else {
        var header = $("#header");
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
    
            if (scroll >= 100) {
                header.addClass("-dark");
            } else {
                header.removeClass("-dark");
            }
        });    
    };

    if ($(window).width() < 992) {
        $(".header-nav-icon").on("click", function(){
            if($(this).hasClass("submenu-li-active")) {
                $(this).removeClass("submenu-li-active");
                $(this).find(".sub-menu").removeClass("submenu-open");
            } else {
                $(".sub-menu").removeClass("submenu-open");
                $(".header-nav-icon").removeClass("submenu-li-active");
                $(this).addClass("submenu-li-active");
                $(this).find(".sub-menu").addClass("submenu-open");
            }
        });
    };
});
