import { default as $ } from 'jquery';

$(window).on("load",function() {
   $(".accordion-content").slideUp();
   $(".accordion-chevron").removeClass("-upsideDown");
   
   $(".accordion-heading").on("click", function(){
      if ($(this).closest(".accordion-section").hasClass("-noShow")) {
         $(this).closest(".accordion-section").removeClass("-noShow");
         $(this).closest(".accordion-section").find(".accordion-content").slideToggle();
         $(this).closest(".accordion-section").find(".accordion-chevron").toggleClass("-upsideDown");
      } else {
         $(this).closest(".accordion-section").addClass("-noShow");
         $(this).closest(".accordion-section").find(".accordion-content").slideUp();
         $(this).closest(".accordion-section").find(".accordion-chevron").removeClass("-upsideDown");
      };
   });

   $(".m-accordion-content").slideUp();
   $(".m-accordion-chevron").removeClass("-upsideDown");
   
   $(".m-accordion-heading").on("click", function(){
      if ($(this).closest(".m-accordion-section").hasClass("-noShow")) {
         $(this).closest(".m-accordion-section").removeClass("-noShow");
         $(this).closest(".m-accordion-section").find(".m-accordion-content").slideToggle();
         $(this).closest(".m-accordion-section").find(".m-accordion-chevron").toggleClass("-upsideDown");
      } else {
         $(this).closest(".m-accordion-section").addClass("-noShow");
         $(this).closest(".m-accordion-section").find(".m-accordion-content").slideUp();
         $(this).closest(".m-accordion-section").find(".m-accordion-chevron").removeClass("-upsideDown");
      };
   });

   $(".m-level-accordion-content").slideUp();
   $(".m-level-accordion-chevron").removeClass("-upsideDown");
   
   $(".m-level-accordion-heading").on("click", function(){
      if ($(this).closest(".m-level-accordion-section").hasClass("-noShow")) {
         $(this).closest(".m-level-accordion-section").removeClass("-noShow");
         $(this).closest(".m-level-accordion-section").find(".m-level-accordion-content").slideToggle();
         $(this).find(".m-level-accordion-chevron").toggleClass("-upsideDown");
      } else {
         $(this).closest(".m-level-accordion-section").addClass("-noShow");
         $(this).closest(".m-level-accordion-section").find(".m-level-accordion-content").slideUp();
         $(this).find(".m-level-accordion-chevron").removeClass("-upsideDown");
      };
   });

   $(".gold-schools").on("click", function(){
      $(this).toggleClass("-upsideDown");
      $("#gold-schools").slideToggle();
   });

   $(".silver-schools").on("click", function(){
      $(this).toggleClass("-upsideDown");
      $("#silver-schools").slideToggle();
   });

   $(".bronze-schools").on("click", function(){
      $(this).toggleClass("-upsideDown");
      $("#bronze-schools").slideToggle();
   });
});