import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-links-carousel-alt.-isCarousel').each(function(i, obj) {
        var dots = $(this).attr('id');

        $(this).slick({
            dots: false,
            arrows: true,
            prevArrow: '<button type="button" class="slick-prev me-4"><i class="fa-solid fa-arrow-left-long" aria-hidden="true"></i><span class="fa-sr-only">Previous arrow</span></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa-solid fa-arrow-right-long" aria-hidden="true"></i><span class="fa-sr-only">Next arrow</span></button>',
            appendArrows: $('.m-links-arrows-alt.'+dots),
            swipeToSlide: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            pauseOnHover: true,
            variableWidth: false,
            useAutoplayToggleButton: false,
            rows: 0,
            responsive: [
                {
                    breakpoint: 1800,
                    settings: {
                        slidesToShow: 3.4,
                    }
                },
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 3.2,
                    }
                },
                {
                    breakpoint: 1450,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 2.8,
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 2.4,
                    }
                },
                {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1.7,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1.5,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1.3,
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        });
    });

    $(".user-selection.accordian .user-selection__header").click(function(event) {
        $(this).closest(".user-selection").find(".user-selection__options").slideToggle();
        $(this).closest(".user-selection").toggleClass("active");
    });

    $(".user-selection__option").click(function(event) {
        if ($(this).closest(".user-selection").hasClass("allow-multi")){
            $(this).toggleClass("active");
            $(this).find(".checkbox-element").toggleClass("active");
        }else{
            $(this).closest(".user-selection").find(".user-selection__option").removeClass("active");
            $(this).closest(".user-selection").find(".checkbox-element").removeClass("active");
            $(this).addClass("active");
            $(this).find(".checkbox-element").addClass("active");
        }
    });

    $("#get-rpl-results").click(function(event) {
        event.preventDefault();

        var scenario = $("#scenario-column").find(".user-selection__option.active").data("taxonomy-value");
        var outcome = $("#outcome-column").find(".user-selection__option.active").data("taxonomy-value");
        var learner = $("#learner-switch").val();

        if ($("#learner-switch").is(":checked")){
            learner = "97";
        } else {
            learner = "96";
        }

        var errorMsg = "";

        if (outcome == undefined || scenario == undefined){
            errorMsg = "Sorry, please make sure you choose an outcome and a scenario";

            $("#modal-rpl-error .error-message").text(errorMsg);
            $("#rpl-error-button").trigger("click"); 
        }

        $(".rpl-result-item").removeClass('active-result');

        var target = scenario + "-" + outcome + "-" + learner ;

        $("#" + target).addClass('active-result');
        
        document.getElementById(target).scrollIntoView({
            behavior: "smooth" 
        });
    });
});