import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $(".m-tabs-card").hide();
    $(".m-tabs-card:first").show();
    $(".m-tabs-tab:first").addClass("-active");

    $(".m-tabs-tab").on("click", function(){
        var tab = $(this).attr('id');

        console.log(tab);
        $(".m-tabs-tab").removeClass("-active");
        $(this).addClass("-active");

        $('.m-tabs-card').each(function(i, obj) {
            if($(this).hasClass(tab)) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });
     });
});