import 'jquery';
import 'bootstrap';
import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-banner-main-carousel.-isCarousel').slick({
        dots: true,
        arrows: false,
        appendDots: $('.m-banner-main-dots'),
        swipeToSlide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        variableWidth: false,
        useAutoplayToggleButton: false,
        rows: 0,
    });

    $(".m-banner-main-modal-close").on("click", function(){
        $(this).closest(".modal-content").find("video").get(0).pause();
    });

    function playVideo(modal) {
        $(modal).find("video").get(0).play();
    }

    function pauseVideo(modal) {
        $(modal).find("video").get(0).pause();
    }

    $(".banner-button-modal").on("click", function(){
        var targetModal = $(this).data('bs-target');
        playVideo(targetModal);
    });
    
    const modals = document.querySelectorAll('.modal');

    modals.forEach(modal => {
        modal.addEventListener('hidden.bs.modal', event => {
            pauseVideo(modal);
        });
    });
});