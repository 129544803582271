import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $(".m-framework-table-icon").removeClass("-upsideDown");
   
    $(".m-framework-table-button").on("click", function(){
        var rowclass = $(this).closest(".m-framework-table-row").data("row");

        $(this).find(".m-framework-table-icon").toggleClass("-upsideDown");
        $("." + rowclass).slideToggle();
   });

   $(".table-border").on("click", function(){
        var rowclass = $(this).closest(".m-framework-table-row").data("row");

        $(this).closest(".m-framework-table-row").find(".m-framework-table-icon").toggleClass("-upsideDown");
        $("." + rowclass).slideToggle();
    });
});